import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

// Popup
//
// For the annoying signup popup.
//
// Values:
//
// - `delay`: The number of miliseconds to wait before showing the popup.
export default class extends Controller {
  static values = {
    delay: {
      default: 1000,
      type: Number,
    }
  }

  connect() {
    super.connect()

    if (!this.popupSeenRecently()) {
      setTimeout(this.toggle.bind(this), this.delayValue)
    }
  }

  popupSeenRecently() {
    const value = Cookies.get("popupLastSeen")
    return value !== undefined
  }

  storePopupSeen() {
    Cookies.set(
      "popupLastSeen",
      new Date(),
      {
        expires: 1, // day
        sameSite: "strict"
      }
    );
  }

  toggle() {
    this.storePopupSeen()
    this.element.classList.toggle('translate-y-full')
    this.element.classList.toggle('mb-0')
    this.element.classList.toggle('mb-10')
  }
}
