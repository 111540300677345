import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "latitude", "longitude"]

  static values = {
    key: String
  }

  connect() {
    // Wait for Google Maps to load
    window.handleGoogleMapsLoaded = window.handleGoogleMapsLoaded || this.handleGoogleMapsLoaded.bind(this)

    if (this.keyValue) {
      this.loadGoogleMaps(this.keyValue)
    } else {
      console.warn(
        "Cannot load Google Maps without an API key. " +
        "Use data-places-autocomplete-key='key' to configure it."
      )
    }
  }

  // Callback function for when Google Maps has finished loading.
  handleGoogleMapsLoaded() {
    window.googleMapsIsLoaded = true

    this.setupGoogleMaps()
  }

  // Callback for when a user selects a place in the Autocomplete dropdown
  handlePlaceChanged() {
    var place = this.autocomplete.getPlace();
    if (place && place.geometry && place.geometry.location) {
      this.latitudeTarget.value = place.geometry.location.lat()
      this.longitudeTarget.value = place.geometry.location.lng()
    }
  }

  isGoogleMapsLoaded() {
    return window.googleMapsIsLoaded
  }

  isGoogleMapsScriptPresent() {
    return !!document.getElementById('google-maps-sdk')
  }

  // Load Google Maps SDK
  loadGoogleMaps(key) {
    if (this.isGoogleMapsLoaded()) return
    if (this.isGoogleMapsScriptPresent()) {
      // Some other controller has initiated a load of the SDK, but it hasn't
      // completed yet. Let's wait a second before hooking up this controller
      // and hope Google manages to respond before then.
      setTimeout(this.handleGoogleMapsLoaded.bind(this), 1000)
      return
    }

    var script = document.createElement('script');

    script.id = 'google-maps-sdk';
    script.setAttribute('async', 'true');
    script.setAttribute('defer', 'true');

    const urlOptions = `key=${key}&libraries=places&callback=handleGoogleMapsLoaded`
    script.src = `https://maps.googleapis.com/maps/api/js?${urlOptions}`;

    document.body.append(script);
  }

  setupGoogleMaps() {
    const options = {
      componentRestrictions: { country: "dk" },

      // "geometry" field returns a PlaceGeometry object
      // https://developers.google.com/maps/documentation/javascript/reference/places-service?authuser=1#PlaceGeometry
      fields: ["geometry"],

      strictBounds: true
    };

    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputTarget,
      options
    )

    this.autocomplete.addListener(
      'place_changed',
      this.handlePlaceChanged.bind(this)
    )
  }
}
