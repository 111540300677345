import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.pushData();
  }

  pushData() {
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}

    gtag('js', new Date());
    gtag('config', 'G-7TRFLHXYSX');
  }
}
