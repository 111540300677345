import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'submit', 'submitContainer']

  connect() {
    this.element.addEventListener('change', this.toggleSubmit.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('change', this.toggleSubmit.bind(this))
  }

  toggleSubmit() {
    if (this.inputTargets.some((input) => input.checked)) {
      this.submitTarget.disabled = false
      this.submitContainerTarget.classList.remove('hidden')
    } else {
      this.submitTarget.disabled = true
      this.submitContainerTarget.classList.add('hidden')
    }
  }
}
